<div class="select-search-load-more">
  <label class="label-for-select" *ngIf="titleNotShowLabel">{{
    titleNotShowLabel
  }}</label>
  <mat-form-field
    class="full-width-field custom-label"
    floatLabel="{{ floatLabel ? 'auto' : 'never' }}"
    [ngClass]="invalid == true ? 'ng-invalid mat-form-field-invalid' : ''"
  >
    <mat-label *ngIf="showLabel" [class.fs-16]="adjust">{{
      placeholderLabel
    }}</mat-label>
    <div [class.h-46]="adjust" class="input-custom pr-10px" [ngClass]="{'disableOnMobile': disableOnMobile}">
      <mat-select
        [class.fs-16]="adjust"
        [disabled]="disabled"
        [formControl]="selectCtrl"
        [(ngModel)]="defaultID"
        [multiple]="multiple"
        [required]="required"
        (change)="emitChangeValue($event)"
        #myInputRef
        id="matSelectLoadmore"
        [class]="panelclass"
        [matTooltip]="
          selectedItem
            ? selectedItem[columnName] +
              ' ' +
              selectedItem.select_search_load_more_label
            : ''
        "
      >
        <mat-select-trigger *ngIf="columnStatusCompany && selectedItem">
          {{ selectedItem[columnName] }}
          <span class="status-company">
            {{
              selectedItem.is_approve == null ? ' - Đơn vị chờ duyệt' : ''
            }}</span
          >
        </mat-select-trigger>
        <mat-option>
          <ngx-mat-select-search
            [placeholderLabel]="placeholderLabel"
            [noEntriesFoundLabel]="noEntriesFoundLabel"
            [formControl]="searchControl"
            (ngModelChange)="search($event)"
            disableScrollToActiveOnOptionsChanged="false"
          >
          </ngx-mat-select-search>
        </mat-option>
        <mat-option (click)="unselect()" *ngIf="multiple != true"
          >-- Bỏ chọn --</mat-option
        >
        <mat-option
          [id]="item[columnValue]"
          *ngFor="let item of filteredInput"
          [value]="item[columnValue]"
          [matTooltip]="
            item[columnName] + ' ' + item.select_search_load_more_label
          "
        >
          {{
            optionColumnName?.length > 0
              ? '[' +
                item[columnName] +
                '] ' +
                item.select_search_load_more_label
              : item[columnName]
          }}
          <span class="status-company" *ngIf="columnStatusCompany">
            {{ item.is_approve == null ? ' - Đơn vị chờ duyệt' : '' }}</span
          >
        </mat-option>

        <div *ngIf="addNew" class="add-new">
          <button (click)="AddNew()" type="button" mat-button class="btn-add">
            <div class="d-flex justify-content-between" style="width: 100%">
              <span>
                <img src="../../../../assets/icons/svg/add-blue.svg" />{{
                  titleAddNew
                }}</span
              >
              <img
                *ngIf="isWarning"
                [matTooltip]="
                  'Đơn vị được chọn sẽ tự động được thêm vào danh sách đơn vị chủ trì của chủ nhiệm'
                "
                src="../../../../assets/icons/svg/info.svg"
              />
            </div>
          </button>
        </div>

        <div
          class="loadMore"
          id="testLoadMore"
          *ngIf="page < totalPage && filteredInput.length >= 10"
        >
          <a href="javascript:void(0);" (click)="loadMore($event)">Xem thêm</a>
        </div>
      </mat-select>
    </div>
  </mat-form-field>
  <div class="input-error-label" *ngIf="invalid" [innerHTML]="errorLabel"></div>
</div>
