import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  forwardRef,
  ChangeDetectorRef,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {environment} from '@app/../environments/environment';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import {Configuration} from '@app/configuration';
import {CustomHttpUrlEncodingCodec} from '@app/encoder';
import {MatSelect} from '@angular/material/select';
import {WorkflowIndustryService} from '@app/api/workflowIndustry.service';

@Component({
  selector: 'meu-control-select-search-load-more',
  templateUrl: './control-select-search-load-more.component.html',
  styleUrls: ['./control-select-search-load-more.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ControlSelectSearchLoadMoreComponent),
      multi: true,
    },
  ],
})
export class ControlSelectSearchLoadMoreComponent implements OnInit, OnChanges {
  @Input() placeholderLabel: string;
  @Input() noEntriesFoundLabel: string;
  @Input() defaultID: string;
  @Input() nameAPI: string;
  @Input() panelclass: string;
  @Input() required: boolean;
  @Input() multiple: boolean;
  @Input() stringFilter: string;
  @Input() columnName: string;
  @Input() optionColumnName: [];
  @Input() columnValue: string = 'id';
  @Input() disabled: boolean = false;
  @Input() invalid: boolean = false;
  @Input() showLabel: boolean = true;
  @Input() titleNotShowLabel: string;
  @Input() errorLabel: string = 'Vui lòng chọn dữ liệu thích hợp';
  @Input() addNew: boolean = false;
  @Input() titleAddNew: string = '';
  @Input() columnStatusCompany: boolean = false;
  @Output() change = new EventEmitter();
  @Output() changeItem = new EventEmitter();
  @Output() eventAdd = new EventEmitter();
  @Input() adjust: boolean;
  @Input() isWarning: boolean = false;
  @Input() disableOnMobile: boolean = false;
  @ViewChild('myInputRef', {static: false}) myInputRef: MatSelect;

  /* @floatLabel:
   * true => show label in normally
   * false => hide label when input has value
   */
  @Input() floatLabel = true;

  cacheSelectedItem = [];
  selectedItem;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.nameAPI != undefined) {
      this.page = 1;
      this.cacheSelectedItem = [];
      this.filteredInput = [];
    }
    if (changes.stringFilter != undefined) {
      this.page = 1;
      this.cacheSelectedItem = [];
      this.filteredInput = [];
      this.filters = this.stringFilter;
      this.runAPI(this.nameAPI);
    } else {
      this.runAPI(this.nameAPI);
    }
    if (changes.page != undefined) {
      this.runAPI(this.nameAPI);
    }
    if (!!changes.invalid && changes.invalid.currentValue) {
      this.triggerCheckError();
    }
  }
  ngOnInit() {
    this.selectCtrl.valueChanges.subscribe((res) => {
      this.emitChangeValue(this.selectCtrl.value);
      if (res != null && res != undefined) {
        let item = this.filteredInput.find((x) => x.id == res);
        if (item != undefined) {
          if (!this.multiple) {
            this.cacheSelectedItem = [];
          }
          this.cacheSelectedItem.push(item);
        } else {
          if (Array.isArray(res)) {
            for (let i = 0; i < res.length; i++) {
              this.getDataByID(res[i]);
            }
          } else {
            this.getDataByID(res);
          }
        }
      }
    });
  }

  public filteredInput: any[];
  public selectCtrl: FormControl = new FormControl();
  public searchControl: FormControl = new FormControl();
  public page = 1;
  public filters = null;
  public totalPage = 0;
  private _value: any;
  get value() {
    return this._value;
  }
  set value(val) {
    this._value = val;
    this.propagateChange(this._value);
  }

  search(event) {
    this.page = 1;
    this.filteredInput = [];
    let optionSearch = '';
    if (this.optionColumnName?.length > 0) {
      optionSearch = '|' + this.optionColumnName.join('|');
    }
    this.filters = null;
    if (event != '' || event != null) {
      if (this.stringFilter) {
        this.filters =
          this.stringFilter +
          ',' +
          (this.nameAPI.includes('/api/user')
            ? 'full_name_unaccent'
            : this.columnName) +
          //option search
          (this.optionColumnName?.length > 0 ? optionSearch : '') +
          '@=*' +
          event;
      } else {
        this.filters =
          (this.nameAPI.includes('/api/user')
            ? 'full_name_unaccent'
            : this.columnName) +
          //option search
          (this.optionColumnName?.length > 0 ? optionSearch : '') +
          '@=*' +
          event;
      }
    } else {
      if (this.stringFilter != null || this.stringFilter != '') {
        this.filters = this.stringFilter;
      }
    }
    if (this.nameAPI.includes('/api/workflowIndustry/getAll')) {
      this.workflowIndustryService
        .apiWorkflowIndustryGetAllGet()
        .subscribe((res) => {
          let data = res.data;
          let filteredData = data.filter((item) =>
            item[this.columnName].toLowerCase().includes(event.toLowerCase())
          );

          this.filteredInput = event ? filteredData : data;
          this.ChangeDetectorRef.detectChanges();
        });
    } else {
      this.runAPI(this.nameAPI, true);
    }

    //this.filterData(this.searchControl.value);
  }
  loadMore(e) {
    this.page = this.page + 1;
    this.runAPI(this.nameAPI);
  }

  triggerCheckError() {
    this.selectCtrl.markAsTouched();
  }
  unselect(): void {
    this.defaultID = null;
  }
  runAPI(nameAPI, isSearch = false) {
    if (nameAPI == null || nameAPI.trim() == '') {
      return;
    }
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (this.filters !== undefined && this.filters !== null) {
      queryParameters = queryParameters.set('Filters', <any>this.filters);
    }
    if (this.page !== undefined && this.page !== null) {
      queryParameters = queryParameters.set('Page', <any>this.page);
    }
    let headers = this.defaultHeaders;
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    this.httpClient
      .request<any>('get', `${environment.backendhost + nameAPI}`, {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
      })
      .subscribe((res) => {
        let array = [...(res.data.collection ? res.data.collection : res.data)];
        this.totalPage = Math.ceil(
          (res.data.total ? res.data.total : res.data.length) / 10
        );
        if (isSearch) {
          this.filteredInput = [];
        }

        if (this.filteredInput.length === 0) {
          array.map((item) => {
            //For custom label
            item.select_search_load_more_label = '';
            if (this.optionColumnName?.length > 0) {
              this.optionColumnName.forEach((element, index) => {
                item.select_search_load_more_label +=
                  index > 0 ? ' - ' : '' + item[element];
              });
            }
            this.filteredInput.push(item);
          });
        } else {
          for (let i = 0; i < array?.length; i++) {
            //For custom label
            array[i].select_search_load_more_label = '';
            if (this.optionColumnName?.length > 0) {
              this.optionColumnName.forEach((element, index) => {
                array[i].select_search_load_more_label +=
                  index > 0 ? ' - ' : '' + array[i][element];
              });
            }
            if (this.cacheSelectedItem != undefined) {
              let index = this.cacheSelectedItem.findIndex(
                (x) => x.id == array[i].id
              );
              let indexfilteredInput = this.filteredInput.findIndex(
                (x) => x.id == array[i].id
              );
              if (index == -1 && indexfilteredInput == -1) {
                this.filteredInput.push(array[i]);
              }
            }
          }
        }
        this.ChangeDetectorRef.detectChanges();
        //scroll to element
        let index = null;
        try {
          if (this.filteredInput.length % 10 == 0) {
            index = this.filteredInput.length - 10;
          } else {
            index = Math.floor(this.filteredInput.length / 10) * 10;
          }
        } catch (error) {}
        if (index != null && index != 0) {
          if (this.filteredInput[index] !== undefined) {
            let elementID = this.filteredInput[index].id;
            if (document.getElementById(elementID) != undefined) {
              setTimeout(function () {
                document.getElementById(elementID).scrollIntoView(false);
              }, 0.5);
            }
          }
        }
      });

    if (
      this.cacheSelectedItem != null &&
      this.cacheSelectedItem != undefined &&
      this.cacheSelectedItem.length > 0
    ) {
      for (let i = 0; i < this.cacheSelectedItem.length; i++) {
        let index = this.filteredInput.findIndex(
          (x) => x.id == this.cacheSelectedItem[i].id
        );
        if (index < 0) {
          this.filteredInput.push(this.cacheSelectedItem[i]);
        }
      }
    }
  }
  getDataByID(id) {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    let filter = this.columnValue + '==' + id;
    queryParameters = queryParameters.set('Filters', <any>filter);
    let headers = this.defaultHeaders;
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    this.httpClient
      .request<any>('get', `${environment.backendhost + this.nameAPI}`, {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
      })
      .subscribe((res) => {
        let array = res.data.collection;
        for (let i = 0; i < array?.length; i++) {
          array[i].select_search_load_more_label = '';
          if (this.optionColumnName?.length > 0) {
            this.optionColumnName.forEach((element, index) => {
              array[i].select_search_load_more_label +=
                index > 0 ? ' - ' : '' + array[i][element];
            });
          }
          if (!array[i].is_draft) {
            this.cacheSelectedItem.push(array[i]);
            let index = this.filteredInput.findIndex(
              (x) => x.id == array[i].id
            );
            if (index < 0) {
              if (this.addNew) {
                this.selectedItem = array[i];
              }
              this.filteredInput.push(array[i]);
            }
          }
        }
        if (this.addNew && this.defaultID) {
          this.selectedItem = this.filteredInput.find(
            (x) => x.id == this.defaultID
          );
        }
        this.ChangeDetectorRef.detectChanges();
      });
  }
  writeValue(value: any): void {
    if (value !== undefined) {
      this.value = value;
    }
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    //throw new Error("Method not implemented.");
  }
  setDisabledState?(isDisabled: boolean): void {
    //throw new Error("Method not implemented.");
  }

  private propagateChange = (_: any) => {};
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  constructor(
    protected httpClient: HttpClient,
    private ChangeDetectorRef: ChangeDetectorRef,
    private workflowIndustryService: WorkflowIndustryService
  ) {}

  emitChangeValue(event) {
    this.value = event;
    this.change.emit(this.value);
    let index = this.filteredInput.findIndex((x) => x.id === this.value);
    this.selectedItem = this.filteredInput[index];
    this.changeItem.emit(this.filteredInput[index]);
  }
  AddNew() {
    this.myInputRef.close();
    this.eventAdd.emit(true);
  }
}
